import styled from 'styled-components';

import { colors } from '../../constants/colors';

const Form = styled.form`



    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        width: 100%;
        background-color: ${colors.grays.light};
        border: none;
        padding: 15px;
        display: block;
        font-weight: 600;
        color: ${colors.grays.darkest};

        &::placeholder {
            color: ${colors.grays.darker};
            font-weight: 400;
        }
    }

    textarea {
        height: 200px;
    }
`

const FormGroup = styled.div`
    margin-bottom: 20px;
`

export { FormGroup };
export default Form;
