import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Band from '../components/Band/Band';
import { Container, ContainerWide } from '../components/Grid/Grid';
import SEO from '../components/SEO';
import GridCards, { Card, ContactCardOverlay } from '../components/GridCards/GridCards';
import DiscoverMore from '../components/DiscoverMore/DiscoverMore';
import Button from '../components/Button/Button';
import Form, { FormGroup } from '../components/Form/Form';
import SR from '../components/SR';

import cardImageLogo from '../images/card-logo-dark.svg';

const ContactPage = ({ data }) => (
    <Layout>

        <SEO title="Contact" description="Contact us at One Planet Retail - we'd love to hear from you." />

        <Band size="xs" pb={0}>
            <ContainerWide>
                <GridCards>
                    <Card image={cardImageLogo} theme="white" />
                    <Card theme="green">
                        <ContactCardOverlay description="Great things are built around the word hello!">Contact Us</ContactCardOverlay>
                    </Card>
                    <Card image={data.cardImage1} />
                </GridCards>
            </ContainerWide>
        </Band>

        <Band size="sm">
            <Container>
                <DiscoverMore id="more">
                    <p>We'd love to hear from you.</p>
                    <Button href="#more">Get In Touch</Button>
                </DiscoverMore>
            </Container>
        </Band>

        <Band size="lg" pt={0}>
            <Container>

                <Form
                    action="/thanks"
                    name="contact"
                    method="post"
                    netlify="true"
                    data-netlify="true"
                    netlify-honeypot="bots"
                    data-netlify-honeypot="bots"
                >
                    <SR>Do not fill out this field: <input type="text" name="bots" /></SR>
                    <FormGroup>
                        <SR><label for="name">Name</label></SR>
                        <input type="text" name="name" id="name" placeholder="Name" required="required" />
                    </FormGroup>
                    <FormGroup>
                        <SR><label for="email">Email</label></SR>
                        <input type="email" name="email" id="email" placeholder="Email" required="required" />
                    </FormGroup>
                    <FormGroup>
                        <SR><label for="telephone">Telephone</label></SR>
                        <input type="tel" name="telephone" id="telephone" placeholder="Telephone" />
                    </FormGroup>
                    <FormGroup>
                        <SR><label for="message">Message</label></SR>
                        <textarea name="message" id="message" placeholder="Message" required="required"></textarea>
                    </FormGroup>
                    <Button as="button" type="submit">Send</Button>
                    <input type="hidden" name="form-name" value="contact" />
                </Form>

            </Container>
        </Band>

    </Layout>
);

export const pageQuery = graphql`
    query {
        cardImage1: file(relativePath: { eq: "card-7.jpg" }) {
            ...cardImageFragment
        }
    }
`

export default ContactPage;
